.main-game-container {
  /* transform: rotateY(30deg); */
  transform-style: preserve-3d;
  width: 78vw;
  height: 100vh;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  perspective: 1000px;
  margin: 0 auto;
}
.hand-container {
  /* transform: rotateY(30deg); */
  transform-style: preserve-3d;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  perspective: 1000px;
  margin: 0 auto;
}