.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.8rem;
    max-width: 88rem;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 0.2rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 4rem;
    cursor: pointer;
    box-shadow: none;
  }
  